<template>
  <aside id="create-channel-modal" class="modal-popup">
    <header>
      <h2>Create a Channel</h2>
      <a href="#" @click.prevent="$emit('done')" class="close"><fa-icon icon="times" /></a>
    </header>

    <footer>
      <div class="controls right">
        <el-button type="success" size="small" round @click="save" :disabled="!canSave" :loading="loading">Create Channel</el-button>
      </div>
    </footer>

    <div class="scroll-view">
      <el-alert show-icon type="error" :title="error" v-if="error !== null" />
      <div class="modal-form">

        <div class="form-row">
          <div class="label">
            <label>Channel Name</label>
          </div>
          <div class="field">
            <input type="text" v-model="$v.name.$model" placeholder="#lets-party" @input="formatChannelName" class="form-control" />
          </div>
        </div>

        <div class="form-row">
          <div class="label">
            <label>Public or private?</label>
          </div>
          <div class="field">
            <el-radio-group v-model="type" size="small">
              <el-radio-button label="PUBLIC">Public</el-radio-button>
              <el-radio-button label="PRIVATE">Private</el-radio-button>
            </el-radio-group>
            <p class="hint-text">Unsure which one to pick? Learn about <a href="https://slack.com/help/articles/360017938993-What-is-a-channel#create-channels" target="_blank" rel="noopener">public vs private channels</a>.</p>
          </div>
        </div>

        <div class="form-row">
          <div class="label">
            <label>Invite Users</label>
          </div>
          <div class="field">
            <user-selector v-model="to_invite" />
          </div>
        </div>

      </div>
    </div>
  </aside>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
export default {
  name: 'create-channel-modal',
  props: ['data'],
  computed: {
    canSave() {
      return !this.$v.$invalid;
    }
  },
  data() {
    return {
      name: '',
      type: 'PUBLIC',
      to_invite: [],
      loading: false,
      error: null
    }
  },
  validations: {
    name: {
      required
    }
  },
  methods: {
    async save() {
      this.error = null;
      this.loading = true;
      const resp = await this.$api.Slack.create_channel(
        this.name.substr(1),
        this.type,
        _.map(this.to_invite, 'platform_id')
      )
      this.loading = false;

      if( resp.ok == false ) {
        if( resp.error == 'name_taken' ) {
          this.error = 'A channel with that name already exists.';
        }
        else {
          this.error = 'An unknown error has occurred.';
        }

        return;
      }
      
      this.$message({
        message: 'New channel successfully created.',
        type: 'success'
      })

      this.$emit('done', resp);
    },
    slugify(str) {
			//str = str.replace(/^\s+|\s+$/g, ''); // trim
			str = str.toLowerCase();

			// remove accents, swap ñ for n, etc
			var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
			var to   = "aaaaeeeeiiiioooouuuunc------";
			for (var i=0, l=from.length ; i<l ; i++) {
					str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
			}

			str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
					.replace(/\s+/g, '-') // collapse whitespace and replace by -
					.replace(/-+/g, '-'); // collapse dashes

			return str;
		},
		formatChannelName() {
			const str = this.slugify(this.name)

			if( str.trim().length == 0 ) {
				this.name = '';
				return;
			}

			this.name = `#` + str;
		}
  }
}
</script>

<style lang="scss" scoped>

</style>